import { SimpleChanges, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UserService } from '@modules/auth/services';
import { sideNavItemsList, sideNavSections } from '@modules/navigation/data/side-nav-dashboard.data';
import { SideNavItems, SideNavSection } from '@modules/navigation/models';
import { NavigationService } from '@modules/navigation/services';
import { Subscription } from 'rxjs';
import { NgbOffcanvas, OffcanvasDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiRequestService } from '@common/services/api-request.service';
import { ToastService } from '@common/services/toast.service';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'sb-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  @Input() sidenavStyle!: string;
  @Input() sideNavItems!: SideNavItems;
  @Input() reloadNav!: string;

  // @Input() sideNavSections!: SideNavSection[];
  sideNavSections = sideNavSections;
  sideNavItemsList = sideNavItemsList;
  subscription: Subscription = new Subscription();
  routeDataSubscription!: Subscription;
  toggle: boolean = false;
  username = "";
  devices: any = [];
  devicesView: any = [];
  page = 1;
  pageSize = 5;

  pageRelease = 1;
  pageSizeRelease = 5;

  releaseNotes: any = [];

  searchForm = this.fb.group({
    searchParams: ['', [Validators.required]],
  });

  closeResult = '';

  @ViewChild('canvas') canvas!: TemplateRef<unknown>;
  @ViewChild('rightcanvas') rightcanvas!: TemplateRef<unknown>;

  constructor(
    public navigationService: NavigationService,
    public userService: UserService,
    private fb: FormBuilder,
    private apiRequest: ApiRequestService,
    private offcanvasService: NgbOffcanvas,
    private toastService: ToastService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
   
    this.username = localStorage.getItem("username") as string;
    this.changeDetectorRef.detectChanges();
    if(!localStorage.getItem("devices")){
      this.getDevices();
    }
    else{
      this.getDevicesJson();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reloadNav?.firstChange) {
        return;
    }
    if(!localStorage.getItem("devices")){
      this.getDevices();
    }
    else{
      this.getDevicesJson();
    }

    this.subscription.unsubscribe();
    console.log(this.sideNavSections[0]);
    this.changeDetectorRef.detectChanges();

  }

  vaidateKeypress(fieldID: any) {
    let allOk = true;
    let inputVal = $("#" + fieldID).val() as string;

    var re = new RegExp("^[A-Za-z0-9]+$");
    if (!re.test(inputVal)) {
      allOk = false
    }

    if (!allOk) {
      $("#" + fieldID).css("border", "1px solid red");
    }
    else {
      $("#" + fieldID).css("border", "1px solid #c5ccd6")
    }


  }


  loadCanvasDevices(){
    this.devicesView = this.devices;
    this.openCanvas();
  }

  async handleInput() {
    //const searchbar = document.querySelector('ion-searchbar');
    //let items = $('#devices').children();
    let query: any = $("#searchField").val();
    query = query.toLowerCase();
    var searchArr: any = [];
    requestAnimationFrame(() => {
      this.devices.forEach( function(item: any) {
        var label = item.deviceName + " " + item.deviceID + " " + item.deviceType; //$(this).find("h2").text();
        // var itemID = item.id.toString();//$(this).prop("id");
        // console.log(itemID);
        if(label != ""){
          let shouldShow = label.toLowerCase().indexOf(query) > -1;
          console.log(shouldShow);
          if (!shouldShow) {
            //$("#"+itemID).hide();
          }
          else {
            //$("#"+itemID).show();
            searchArr.push(item);
          }
        }
        else{
          //document.getElementById(itemID).style.display = "block";
        }

      });
      if (searchArr.length > 0){
        this.devicesView = searchArr;
      } 
      else{
        this.devicesView = this.devices;
      }
      
    });
  }


  getDevicesJson() {
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 2000);

    let body = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    var res: any = JSON.parse(localStorage.getItem('devices') as string);

    // this.apiRequest.apiRequest("utilities/devices/", "devices.json", body)
    //   .subscribe((res) => {
        
        const defaultOptions = {
          autohide: true,
          delay: 5000,
        };

        this.devices = res.data.devices;
        this.devicesView = res.data.devices;
        // if (res.success && res.data.results.length > 0) {
        //   this.ngxService.stop();
        //   this.devices = res.data.results;
        //   this.openCanvas()
        // }
        // else {
        //   this.toastService.show("Device Search", "No devices where found", { headerClasses: 'bg-warning text-light', ...defaultOptions });

        // }
        this.changeDetectorRef.detectChanges();
      // },
      //   (error: any) => {
      //     const defaultOptions = {
      //       autohide: true,
      //       delay: 5000,
      //     };

      //     console.log(error);
      //     let message = "";
      //     for (var i = 0; i < error.error.data.length; i++) {
      //       message += error.error.data[i] + "\n";
      //     }
      //     this.toastService.show("Device Search", error.error.data, { headerClasses: 'bg-danger text-light', ...defaultOptions });

      //   });

  }

  getDevices() {
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 2000);

    let body = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    this.apiRequest.apiRequest("utilities/devices/", "devices.json", body)
      .subscribe((res) => {
        if (res.success) {
          console.log('Save json devices');
            localStorage.setItem('devices', JSON.stringify((res)));
          }

        const defaultOptions = {
          autohide: true,
          delay: 5000,
        };

        this.devices = res.data.devices;
        this.devicesView = res.data.devices;
        // if (res.success && res.data.results.length > 0) {
        //   this.ngxService.stop();
        //   this.devices = res.data.results;
        //   this.openCanvas()
        // }
        // else {
        //   this.toastService.show("Device Search", "No devices where found", { headerClasses: 'bg-warning text-light', ...defaultOptions });

        // }
        this.changeDetectorRef.detectChanges();
      },
        (error: any) => {
          const defaultOptions = {
            autohide: true,
            delay: 5000,
          };

          let message = "";
          for (var i = 0; i < error.error.data.length; i++) {
            message += error.error.data[i] + "\n";
          }
          this.toastService.show("Device Search", message, { headerClasses: 'bg-danger text-light', ...defaultOptions });

        });

  }


  deviceSearch() {
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 2000);

    let body = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
        "searchParams": $("#searchField").val()
      },
    };

    this.apiRequest.apiRequest("utilities/device_search/", "device_search.json", body)
      .subscribe((res) => {
        const defaultOptions = {
          autohide: true,
          delay: 5000,
        };

        console.log(res.message);
        if (res.success && res.data.results.length > 0) {
          this.ngxService.stop();
          this.devices = res.data.results;
          this.openCanvas()
        }
        else {
          this.toastService.show("Device Search", "No devices where found", { headerClasses: 'bg-warning text-light', ...defaultOptions });

        }
        this.changeDetectorRef.detectChanges();
      },
        (error: any) => {
          const defaultOptions = {
            autohide: true,
            delay: 5000,
          };

          console.log(error);
          let message = "";
          for (var i = 0; i < error.error.data.length; i++) {
            message += error.error.data[i] + "\n";
          }
          this.toastService.show("Device Search", error.error.data, { headerClasses: 'bg-danger text-light', ...defaultOptions });

        });

  }

  openCanvas() {
    this.offcanvasService.open(this.canvas, { ariaLabelledBy: 'offcanvas-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }



  loadReleaseCanvas() {
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 2000);

    let body = {
      payload: {
        userID: parseInt(localStorage.getItem("userID") as string),
      },
    };

    // this.openRightCanvas()


    this.apiRequest.apiRequest("common/jira/release_notes/", "release_notes.json", body)
      .subscribe((res) => {
        const defaultOptions = {
          autohide: true,
          delay: 5000,
        };


        console.log(res);

        if (res.success) {
          this.ngxService.stop();
          var newArr: any = [];
          let notes = JSON.parse(res.data);
          console.log(notes)
          for (var j = 0; j < notes.issues.length; j++) {
            let title = notes.issues[j].fields.summary;
            let issuetype = notes.issues[j].fields.issuetype.name;
            let description = ""
            if (notes.issues[j].fields.description != null)
              for (var i = 0; i < notes.issues[j].fields.description.content.length; i++) {
                description += notes.issues[j].fields.description.content[i].content[0].text + "<br />";
              }
            let created = new Date(notes.issues[j].fields.created).toLocaleDateString('zh-Hans-CN');
            newArr.push({ "title": title, "issuetype": issuetype, "description": description, "created": created })
          }
          this.releaseNotes = newArr;

          console.log(this.releaseNotes)
          this.openRightCanvas()
        }
        else {
          this.toastService.show("Release Notes", "No release notes found", { headerClasses: 'bg-warning text-light', ...defaultOptions });

        }
        this.changeDetectorRef.detectChanges();
      },
        (error: any) => {
          const defaultOptions = {
            autohide: true,
            delay: 5000,
          };

          console.log(error);
          let message = "";
          for (var i = 0; i < error.error.data.length; i++) {
            message += error.error.data[i] + "\n";
          }
          this.toastService.show("Device Search", error.error.data, { headerClasses: 'bg-danger text-light', ...defaultOptions });

        });

  }

  openRightCanvas() {
    this.offcanvasService.open(this.rightcanvas, { ariaLabelledBy: 'offcanvas-basic-title', position: 'end' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  viewDevice(deviceID: string, deviceType: string, meterType: string, deviceVersion: string, levelName: string) {
    localStorage.setItem("deviceID", deviceID);
    localStorage.setItem("activeLevel", "device");
    localStorage.setItem("deviceType", deviceType);
    localStorage.setItem("meterType", meterType);
    localStorage.setItem("deviceVersion", deviceVersion);
    localStorage.setItem("device", levelName);

    if (localStorage.getItem("active") != "dashboard") {
      localStorage.setItem("active", "dashboard");

      setTimeout(() => {
        window.location.assign("/dashboard/device")

      }, 500)

    }
    else {
      window.location.assign("/dashboard/device")
    }
    this.offcanvasService.dismiss('Cross click');
  }



  private getDismissReason(reason: any): string {
    if (reason === OffcanvasDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on the backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {

    this.subscription.unsubscribe();
    this.changeDetectorRef.detectChanges();
  }

  openDialogue() {
    this.toggle = !this.toggle;
    console.log(this.toggle);
  }

}
