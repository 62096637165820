import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { environment, environmentTesting, environmentDev, environmentMigration, environmentDemo } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Router } from '@angular/router';

@Injectable()


export class ApiRequestService {
    urlEnvironment = "";
    private cancelRequest$: Subject<void> = new Subject<void>();


    constructor(
        private http: HttpClient,         
        private router: Router,
        ) { }

     //APi Connection
    apiRequest(url: string, jsonFile: string, body: any): Observable<any> {

        if(!localStorage.getItem("userID") && url.indexOf("auth") < 0){
            this.router.navigateByUrl("/auth/login");
        }
        this.urlEnvironment = environment.apiUrl;

        //environment overwrite
        localStorage.setItem("environment", "prod");

        if (localStorage.getItem("environment") == "testing") {
            this.urlEnvironment = environmentTesting.apiUrl;
        }
        else if (localStorage.getItem("environment") == "dev") {
            this.urlEnvironment = environmentDev.apiUrl; 
        }
        else if (localStorage.getItem("environment") == "demo") {
            this.urlEnvironment = environmentDemo.apiUrl;
        }
        else if (localStorage.getItem("environment") == "migration") {
            this.urlEnvironment = environmentMigration.apiUrl;
        }
        

        let accessToken = localStorage.getItem("access_token");

        const headers = { 'content-type': 'application/json', "Authorization": "Bearer " +accessToken }
        
        //demo data fixed
        if(localStorage.getItem("environment") == "demo"){
            return this.http.get( this.urlEnvironment+url+jsonFile, body)
        }else{
            //lets return live data
            let postData = this.http.post(this.urlEnvironment + url, body, {
                'headers': headers,
                'observe': 'body',
            }).pipe(takeUntil(this.cancelRequest$));
            
            return postData;
        }

        
    }

    cancelPendingRequests(): void {
        this.cancelRequest$.next();
        this.cancelRequest$.complete(); // Clean up the Subject
      }

    apiRequestDemo(url: string, jsonFile: string, body: any): Observable<any> {

        if(!localStorage.getItem("userID")){
            this.router.navigateByUrl("/auth/login");
        }
        this.urlEnvironment = environment.apiUrl;


        this.urlEnvironment = environmentDemo.apiUrl;

        let accessToken = localStorage.getItem("access_token");

        const headers = { 'content-type': 'application/json', "Authorization": "Bearer " +accessToken }
        
        //demo data fixed
        return this.http.get( this.urlEnvironment+url+jsonFile, body)
       
        
    }

    apiRequestChatGPT(url: string, jsonFile: string, body: any): Observable<any> {

        if(!localStorage.getItem("userID") && url.indexOf("auth") < 0){
            this.router.navigateByUrl("/auth/login");
        }

        let accessToken = "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";

        const headers = { 'content-type': 'application/json', "Authorization": "Bearer " +accessToken }
        
        //lets return live data
        let postData = this.http.post(url, body, {
            'headers': headers,
            'observe': 'body',
        })
        return postData;

        
    }


    
}
